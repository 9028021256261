import React, { useEffect, useState } from "react";
import { useGetAllTransactions } from "../../api/transactionsApiClient";
import { useNavigate } from "react-router";
import { Container, LinearProgress, List, Snackbar } from "@mui/material";
import { STYLES } from "../../themes/MainTheme";
import TransitionRight from "../shared/TransitionRight";
import TransactionsListRow from "./TransactionsListRow";

export default function TransactionsList() {

  const [snackbarVisible, setSnackbarVisible] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState("")

  const navigate = useNavigate()
  const {data: transactions, isLoading, isError} = useGetAllTransactions(navigate)

  const toggleSnackbar = (visible, message="") => {
    setSnackbarVisible(visible)
    setSnackbarMessage(message)
  
    if (visible) {
      setTimeout(() => setSnackbarVisible(false), 3000)
    }
  }

  useEffect(() => {
    if (isError) {
      toggleSnackbar(true, "Fehler beim laden der Transaktionen")
    }
  }, [isError])

  return (
    <Container maxWidth="md" disableGutters>
      { isLoading && <LinearProgress color="inherit" /> }
      <List style={STYLES.listContainer}>
        {
          transactions && transactions.map((transaction, i) => (
            <TransactionsListRow transaction={transaction} key={i} />
          ))
        }
      </List>
      <Snackbar
        open={snackbarVisible}
        message={snackbarMessage}
        slots={{transition: TransitionRight}}
      />
    </Container>
  )

}