import React from 'react'
import AssignedGroup from './AssignedGroup'

export default function ObjectInstanceList({object, customers, refetchObject}) {

  return (
    <>
      { object.instances && object.instances.length > 0 &&
        <>
          {
            object.instances.map((group, i) => (
              <AssignedGroup
                group={group}
                customers={customers}
                objectId={object._id}
                refetchObject={refetchObject}
                key={i}
              />
            ))
          }
        </>
      }
    </>
  )
}