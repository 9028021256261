import { ListItemButton, ListItemSecondaryAction, ListItemText } from "@mui/material"
import moment from "moment"
import React from "react"
import { STYLES } from "../../themes/MainTheme"
import { useNavigate } from "react-router"

export default function TransactionsListRow(props) {

  const transaction = props.transaction
  const navigate = useNavigate()

  const handleClick = () => {
    if (props.onClick) {
      props.onClick(transaction)
    } else {
      navigate(`/transactions/${transaction._id}`)
    }
  }

  return (
    <ListItemButton
      key={transaction._id}
      divider
      onClick={handleClick}
    >
      <ListItemText
        primary={transaction.name}
        slotProps={{ primary: { style: STYLES.textTruncate }, secondary: { style: STYLES.textTruncate } }}
        secondary={transaction.user ? transaction.user.username : ""}
      />
      <ListItemSecondaryAction>
        <ListItemText
          primary={moment(transaction.date).format("DD.MM.YYYY HH:mm:ss")}
          slotProps={{ primary: { style: STYLES.textTruncate } }}
        />
      </ListItemSecondaryAction>
    </ListItemButton>
  )

}