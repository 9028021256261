import React, { useState } from 'react'
import { SPACINGS } from '../../themes/MainTheme'
import { Box, Button, Container, FormControl, InputLabel, Menu, MenuItem, Paper, Select, Stack, TextField, Typography } from '@mui/material'
import { Add, Remove, QrCodeScanner, FileDownloadOutlined } from "@mui/icons-material";
import AmountDialog from '../shared/AmountDialog';
import { useAddInstances, useRemoveInstances, useUpdateCustomer } from '../../api/objectsApiClient';
import { useNavigate } from 'react-router';
import Restricted from '../shared/Restricted';
import useGlobal from '../shared/GlobalContext';
import CONSTS from '../../utils/consts';

export default function AssignedGroup({group, customers, objectId, refetchObject}) {

  const [listAssignAnchor, setListAssignAnchor] = useState(null)
  const isMenuopen = Boolean(listAssignAnchor)

  const [showAssignAmountDialog, setShowAssignAmountDialog] = useState(false)
  const [showAddAmountDialog, setShowAddAmountDialog] = useState(false)
  const [showRemoveAmountDialog, setShowRemoveAmountDialog] = useState(false)
  const updateCustomerMutation = useUpdateCustomer(() => {refetchObject()})
  const addInstancesMutation = useAddInstances(() => {refetchObject()})
  const removeInstancesMutation = useRemoveInstances(() => {refetchObject()})

  const navigate = useNavigate()
  const { assignTypeId, toggleQrModal, toggleListAssignModal, setInstancesToStore, setAssignTypeId } = useGlobal()

  const changeCustomer = (customer) => {
    updateCustomerMutation.mutate({
      objectId,
      customerData: {
        instancesToUpdate: group,
        customer
      },
      navigate
    })
  }

  const handleQrAssign = () => {
    setShowAssignAmountDialog(true)
    setAssignTypeId(null)
  }

  const handleListAssign = (event) => {
    setListAssignAnchor(event.currentTarget)
  }

  const handleMenuClose = () => {
    setListAssignAnchor(null)
  }

  const handleStorageClick = () => {
    setListAssignAnchor(null)
    setShowAssignAmountDialog(true)
    setAssignTypeId(CONSTS.storageTypeId)
  }

  const handleProjectClick = () => {
    setListAssignAnchor(null)
    setShowAssignAmountDialog(true)
    setAssignTypeId(CONSTS.projectTypeId)
  }

  const handlePackingListClick = () => {
    setListAssignAnchor(null)
    setShowAssignAmountDialog(true)
    setAssignTypeId(CONSTS.packingListTypeId) 
  }

  const addObjects = (amount) => {
    addInstancesMutation.mutate({
      objectId, 
      instanceData: {
        instanceToCopy: group[0],
        amount
      },
      navigate
    })
  }

  const removeObjects = (amount) => {
    removeInstancesMutation.mutate({
      objectId,
      instanceData: {
        instancesToDelete: group.slice(0,amount)
      },
      navigate
    })
  }

  const assignObject = (amount) => {
    let instances = group.slice(0,amount)

    if (assignTypeId === null) {
      toggleQrModal(true)
      setInstancesToStore({
        objectId,
        instances
      })
    } else {
      toggleListAssignModal(true)
      setInstancesToStore({
        objectId,
        instances
      })
    }
  }

  const shouldShowStorage = group[0].storage && (group[0].storage.name !== CONSTS.emptyStorageName || group[0].project.name === CONSTS.emptyProjectName)
  const shouldShowProject = group[0].project && group[0].project.name !== CONSTS.emptyProjectName

  return (
    <Paper style={styles.container} square elevation={1}>
      <Box style={styles.numberBox}>
        <Restricted to="objects.addInstances">
          <Button onClick={() => setShowAddAmountDialog(true)}>
            <Add fontSize='large' />
          </Button>
        </Restricted>
        <Typography fontSize={28}>
          {group.length}
        </Typography>
        <Restricted to="objects.removeInstances">
          <Button onClick={() => setShowRemoveAmountDialog(true)}>
            <Remove fontSize='large' />
          </Button>
        </Restricted>
      </Box>
      <Box style={styles.formContainer}>
        <Stack spacing={2} style={styles.stack}>
          <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
            <Restricted
              to="objects.changeCustomer"
              fallback={
                <TextField
                  slotProps={{ htmlInput: { readOnly: true } }}
                  variant="standard"
                  label="Kunde"
                  value={group[0].customer == null ? "" : group[0].customer.name}
                />
              }
            >
              <InputLabel id="customer-select">Kunde</InputLabel>
              <Select
                labelId="customer-select"
                value={group[0].customer == null ? "" : group[0].customer._id}
                onChange={(e) => changeCustomer(e.target.value)}
                label="Kunde"
              >
                {
                  customers && customers.map((customer, i) => (
                    <MenuItem value={customer._id} key={i}>{customer.name}</MenuItem>
                  ))
                }
              </Select>
            </Restricted>
          </FormControl>
          { shouldShowStorage &&
            <TextField
              label="Lagerplatz"
              slotProps={{ htmlInput: { readOnly: true } }}
              variant="standard"
              value={`${group[0].storage.name} ${group[0].storageSlots.join(", ")}`}
            />
          }
          { shouldShowProject &&
            <TextField
              label="Projekt"
              slotProps={{ htmlInput: { readOnly: true } }}
              variant="standard"
              value={group[0].project.name}
            />
          }
          <Restricted to="objects.assign,objects.store,objects.pack">
            <Container disableGutters style={styles.assignContainer}>
                <Button type="button" variant="contained" style={styles.assignButton} onClick={handleQrAssign}>
                  <><QrCodeScanner style={{marginRight: SPACINGS.s}} />Zuweisen</>
                </Button>
                <Button type="button" variant="contained" style={styles.assignButton} onClick={handleListAssign}>
                  <><FileDownloadOutlined style={{marginRight: SPACINGS.s}} />Zuweisen</>
                </Button>
                <Menu
                  anchorEl={listAssignAnchor}
                  open={isMenuopen}
                  onClose={handleMenuClose}
                >
                  <MenuItem onClick={handleStorageClick}>LagerPlatz</MenuItem>
                  <MenuItem onClick={handleProjectClick}>Projekt</MenuItem>
                  <MenuItem onClick={handlePackingListClick}>Ladeliste</MenuItem>
                </Menu>
            </Container>
          </Restricted>
          <AmountDialog
            isOpen={showAssignAmountDialog}
            onClose={() => setShowAssignAmountDialog(false)}
            onConfirm={assignObject}
            message={"Wie viele Objekte sollen zugewiesen werden?"}
            maxAmount={group.length}
            allAmount={group.length}
          />
          <AmountDialog
            isOpen={showAddAmountDialog}
            onClose={() => setShowAddAmountDialog(false)}
            onConfirm={addObjects}
            message={"Wie viele Objekte möchtest Du hinzufügen?"}
            maxAmount={999}
          />
          <AmountDialog
            isOpen={showRemoveAmountDialog}
            onClose={() => setShowRemoveAmountDialog(false)}
            onConfirm={removeObjects}
            message={"Wie viele Objekte möchtest Du entfernen?"}
            maxAmount={group.length}
            allAmount={group.length}
          />
        </Stack>
      </Box>
    </Paper>
  )
}

const styles = {
  container: {
    flex: 1,
    display: "flex",
    flexDirection: "row",
    marginTop: SPACINGS.s,
    marginBottom: SPACINGS.s
  },
  numberBox: {
    width: "10%",
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    flexDirection: "column"
  },
  formContainer: {
    flex: 1,
    display: "flex"
  },
  stack: {
    width: "100%",
    padding: SPACINGS.s
  },
  instanceActionButton: {
    width: "49%",
    backgroundColor: "black"
  },
  assignContainer: {
    justifyContent: "space-between",
    display: "flex"
  },
  assignButton: {
    width: "49%",
    backgroundColor: "black"
  }
}