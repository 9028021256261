import { Container, LinearProgress, List, Snackbar } from '@mui/material'
import React, { useEffect, useState, useRef } from 'react'
import { useNavigate } from 'react-router'
import { useGetAllCustomers } from '../../api/customersApiClient'
import { STYLES } from '../../themes/MainTheme'
import TransitionRight from '../shared/TransitionRight'
import CustomersListRow from './CustomersListRow'
import Searchbar from '../shared/Searchbar'

export default function CustomersList() {

  const [searchQuery, setSearchQuery] = useState("")
  const [snackbarVisible, setSnackbarVisible] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState("")
  const hasRestoredState = useRef(false)

  const navigate = useNavigate()
  const { data: customers, isLoading, isError } = useGetAllCustomers(searchQuery, navigate)
  const listKey = `listState-${window.location.pathname}`

  const toggleSnackbar = (visible, message="") => {
    setSnackbarVisible(visible)
    setSnackbarMessage(message)
  
    if (visible) {
      setTimeout(() => setSnackbarVisible(false), 3000)
    }
  }

  useEffect(() => {
    if (isError) {
      toggleSnackbar(true, "Fehler beim laden der Kunden")
    }
  }, [isError])

  // Store the search query in the session storage
  const handleNavigate = (customer) => {
    sessionStorage.setItem(`${listKey}-searchQuery`, searchQuery)
    navigate(`/customers/${customer._id}`)
  }

  // Restore the search query from the session storage
  useEffect(() => {
    if (hasRestoredState.current) return;
    const savedQuery = sessionStorage.getItem(`${listKey}-searchQuery`)
    if (savedQuery) setSearchQuery(savedQuery);
    hasRestoredState.current = true;
  }, [listKey, searchQuery]);

  return (
    <Container maxWidth="md" disableGutters>
      <Searchbar
        class="searchbar-list"
        searchText={searchQuery}
        onChange={(value) => setSearchQuery(value)}
        onClear={() => setSearchQuery("")}
      />
      { isLoading && <LinearProgress color="inherit" /> }
      <List style={STYLES.listContainer}>
        {
          customers && customers.map((customer, i) => (
            <CustomersListRow customer={customer} key={i} onClick={handleNavigate} />
          ))
        }
      </List>
      <Snackbar
        open={snackbarVisible}
        message={snackbarMessage}
        slots={{transition: TransitionRight}}
      />
    </Container>
  )
}