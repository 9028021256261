import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import useGlobal from "../shared/GlobalContext";
import { Box, Button, Container, Grid2, LinearProgress, Paper, Snackbar, Stack, TextField } from "@mui/material";
import { Add, Delete, Save } from "@mui/icons-material";
import { SPACINGS, STYLES } from "../../themes/MainTheme";
import BackButton from "../shared/BackButton";
import { useAddInstances, useAddObject, useDeleteObject, useGetObject, useUpdateObject } from "../../api/objectsApiClient";
import Restricted from "../shared/Restricted";
import ImageUploadButton from "../shared/ImageUploadButton";
import ObjectInstanceList from "./ObjectInstanceList";
import { useGetAllCustomers } from "../../api/customersApiClient";
import cropImage from "../../utils/imageCropper";
import DeleteDialog from "../shared/DeleteDialog";
import SERVERCONFIG from "../../utils/serverConfig";
import AmountDialog from "../shared/AmountDialog";
import TransitionRight from "../shared/TransitionRight";

export default function ObjectDetail() {
  const params = useParams();
  const hasObject = params.id !== "add";

  const [formData, setFormData] = useState({
    name: '',
    description: '',
    amount: '',
    customer: '',
    image: null
  })
  const [shouldClose, setShouldClose] = useState(false)
  const [imageUrl, setImageUrl] = useState("")
  const [showDeleteDialog, setShowDeleteDialog] = useState(false)
  const [snackbarVisible, setSnackbarVisible] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState("")
  const [showAddAmountDialog, setShowAddAmountDialog] = useState(false)

  const navigate = useNavigate()

  const onClose = () => {
    if (imageUrl) {
      URL.revokeObjectURL(imageUrl)
    }
    navigate('/')
  }

  const onAdd = (object) => {
    toggleSnackbar(true, "Objekt erfolgreich angelegt")
    if (shouldClose) {
      onClose()
    } else {
      navigate("/"+object._id)
    }
  }

  const toggleSnackbar = (visible, message="") => {
    setSnackbarVisible(visible)
    setSnackbarMessage(message)
  
    if (visible) {
      setTimeout(() => setSnackbarVisible(false), 3000)
    }
  }

  const { showQrModal } = useGlobal()

  const { data: object, isLoading: isLoadingObject, isError: isErrorObject, refetch } = useGetObject(params.id, navigate, hasObject)
  const {data: customers, isLoading: isLoadingCustomers, isError: isErrorCustomers } = useGetAllCustomers(navigate)
  const addMutation = useAddObject(onAdd)
  const updateMutation = useUpdateObject(onClose)
  const deleteMutation = useDeleteObject(() => {setShowDeleteDialog(false);onClose();})
  const addInstancesMutation = useAddInstances(() => {refetch()})

  const handleCapture = (target) => {
    if (target.files) {
      if (target.files.length !== 0) {
        const file = target.files[0]

        cropImage(file, 500, 500).then((croppedBlob) => {
          setImageUrl(URL.createObjectURL(croppedBlob))
          setFormData({...formData, image: croppedBlob})
        });
      }
    }
  }

  const refetchObject = useCallback(() => {
    if (hasObject) {
      refetch()
    }
  }, [refetch, hasObject])

  const checkInput = () => {
    if (!formData.name || !formData.amount) {
      toggleSnackbar(true, "Name und Anzahl dürfen nicht leer sein.")
      return false
    }
    return true
  }

  const saveOrUpdateForm = async() => {
    if (checkInput()) {
      if (hasObject) {
        updateMutation.mutate({objectId: params.id, formData, navigate})
      } else {
        addMutation.mutate({formData, navigate})
      }
    }
  }

  const handleSaveButton = () => {
    setShouldClose(true)
    saveOrUpdateForm()
  }

  const handleAddButton = () => {
    setShouldClose(false)
    saveOrUpdateForm()
  }

  const addInstances = (amount) => {
    addInstancesMutation.mutate({
      objectId: object._id, 
      instanceData: {
        instanceToCopy: {
          storageSlots: [],
          storage: null,
          project: null,
          customer: null
        },
        amount
      },
      navigate
    })
  }

  useEffect(() => {
    if (object) {
      // Fill form
      setFormData({
        name: object.name,
        description: object.description,
        amount: object.amount,
        image: null 
      })
      if (object.image) {
        setImageUrl(SERVERCONFIG.url + object.image)
      }
    }
  }, [object])

  // Error handling
  useEffect(() => {
    if (addMutation.isError || updateMutation.isError) {
      toggleSnackbar(true, "Fehler beim speichern des Objektes")
    } else if (deleteMutation.isError) {
      toggleSnackbar(true, "Fehler beim löschen des Objektes")
    } else if (isErrorObject) {
      toggleSnackbar(true, "Objekt konnte nicht geladen werden")
    } else if (isErrorCustomers) {
      toggleSnackbar(true, "Kunden konnten nicht geladen werden")
    }
  }, [addMutation.isError, updateMutation.isError, deleteMutation.isError, isErrorObject, isErrorCustomers])

  // Refetch object data after storing
  useEffect(() => {
    if (!showQrModal) {
      refetchObject()
    }
  }, [showQrModal, refetchObject])

  const isLoading = addMutation.isPending || updateMutation.isPending || deleteMutation.isPending || isLoadingObject || isLoadingCustomers

  return (
    <Container maxWidth="md" disableGutters>
      <Paper square elevation={1} style={STYLES.detailHeader}>
        <Grid2 container style={{height: SPACINGS.ll}}>
          <Grid2 item size={{ xs: 8 }}>
            <Container style={{height: "100%"}}>
              <BackButton onBack={() => navigate(-1)} />
            </Container>
          </Grid2>
          <Grid2 item size={{ xs: 4 }} style={styles.rightAlignedGrid}>
            <Restricted to={hasObject ? "objects.update" : "objects.create"}>
              <Button style={{...STYLES.buttonV2, ...styles.topActionButton }} onClick={handleSaveButton} >
                <Save fontSize="medium" />
              </Button>
            </Restricted>
            { object &&
              <Restricted to="objects.delete">
                <Button style={{...STYLES.buttonV2, ...styles.topActionButton }} onClick={() => setShowDeleteDialog(true)} >
                  <Delete fontSize="medium" />
                </Button>
                <DeleteDialog
                  isOpen={showDeleteDialog && !deleteMutation.isError}
                  onClose={() => setShowDeleteDialog(false)}
                  message={"Möchtest Du dieses Objekt wirklich löschen?"}
                  handleDelete={() => deleteMutation.mutate({objectId: params.id, navigate})}
                />
              </Restricted>
            }
          </Grid2>
        </Grid2>
      </Paper>
      { isLoading && <LinearProgress color="inherit" /> }
      <Container style={styles.mainContainer}>
        <Paper style={styles.imageUploadContainer} square elevation={1}>
          <Box style={styles.imageBox}>
            { imageUrl &&
              <img src={imageUrl} alt="" style={styles.image} />
            }
          </Box>
          <Restricted to={hasObject ? "objects.update" : "objects.create"}>
            <Box sx={styles.uploadButtonsContainer}>
              <ImageUploadButton handleCapture={handleCapture} />
            </Box>
          </Restricted>
        </Paper>
        <Paper style={styles.formContainer} square elevation={1}>
          <Stack spacing={2} style={styles.formStack}>
            <TextField
              label="Name"
              variant="standard"
              value={formData.name}
              onChange={(e) => setFormData({...formData, name: e.target.value})}
            />
            <TextField
              label="Beschreibung"
              variant="standard"
              value={formData.description}
              onChange={(e) => setFormData({...formData, description: e.target.value })}
            />
            <Grid2 container>
              <Grid2 item size={{ xs: hasObject ? 10 : 12 }}>
                <TextField
                  style={{width: "100%"}}
                  label="Anzahl"
                  slotProps={{ htmlInput: { readOnly: hasObject } }}
                  type="number"
                  variant="standard"
                  value={formData.amount}
                  onChange={(e) => setFormData({...formData, amount: e.target.value })}
                />
              </Grid2>
                { hasObject &&
                  <Grid2 item size={{ xs: 2 }} style={{display: "flex", justifyContent:"right"}}>
                    <Restricted to="objects.addInstances">
                      <Button onClick={() => setShowAddAmountDialog(true)}>
                        <Add fontSize="large" />
                      </Button>
                    </Restricted>
                  </Grid2>
                }
            </Grid2>
          </Stack>
        </Paper>
        { object != null ?
          <ObjectInstanceList
            object={object}
            customers={customers}
            refetchObject={refetchObject}
          /> :
          <Restricted to="objects.create">
            <Button onClick={handleAddButton} style={styles.addButton}>
              Objekt anlegen
            </Button>
          </Restricted>
        }
        <AmountDialog
          isOpen={showAddAmountDialog}
          onClose={() => setShowAddAmountDialog(false)}
          onConfirm={addInstances}
          message={"Wie viele Objekte möchtest Du hinzufügen?"}
          maxAmount={999}
        />
        <Snackbar
          open={snackbarVisible}
          message={snackbarMessage}
          slots={{transition: TransitionRight}}
        />
      </Container>
    </Container>
  )
}

const styles = {
  mainContainer: {
    width: "100%",
    height: "92%",
    padding: 0,
    maxHeight: "88%",
    overflowY: "auto"
  },
  imageUploadContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "row"
  },
  formContainer: {
    flex: 1,
    display: "flex",
    marginTop: SPACINGS.s,
    marginBottom: SPACINGS.s
  },
  formStack: {
    width: "100%",
    padding: SPACINGS.s
  },
  uploadButtonsContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "column"
  },
  imageBox: {
    width: "50%",
    aspectRatio: 1,
    display: "flex"
  },
  image: {
    width: "100%",
    objectFit: "cover"
  },
  topActionButton: {
    width: SPACINGS.xl
  },
  rightAlignedGrid: {
    justifyContent: "right",
    flexDirection: "row",
    display: "flex"
  },
  addButton: {
    width: "100%",
    backgroundColor: "black",
    color: "white",
  }
}