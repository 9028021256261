import React from "react"
import { useState } from "react"
import { Avatar, Grid2, ListItemAvatar, ListItemButton, ListItemSecondaryAction, ListItemText } from "@mui/material"
import SERVERCONFIG from "../../utils/serverConfig"
import { SPACINGS, STYLES } from "../../themes/MainTheme"
import ImageModal from "../ImageModal"
import { useNavigate } from "react-router"

export default function ObjectsListRow(props) {
  const object = props.object

  const [isImageModalOpen, setIsImageModalOpen] = useState(false)
  const navigate = useNavigate()

  const handleClick = () => {
    if (props.onClick) {
      props.onClick(object)
    } else {
      navigate(`/${object._id}`)
    }
  }

  const handleImageClick = () => {
    if (object.thumbnail !== null) {
      setIsImageModalOpen(true)
    }
  }

  return (
    <Grid2 container>
      <Grid2 item size={{ xs: 2, sm:1 }}>
        <ListItemButton
          disabled={object.thumbnail === null}
          style={styles.imageListButton}
          key={object._id}
          divider
          onClick={handleImageClick}
        >
          { object.thumbnail !== null &&
            <>
              <ListItemAvatar>
                <Avatar variant="square" sx={{width: 64, height: 64}}>
                  <img
                    src={SERVERCONFIG.url + object.thumbnail}
                    width={64}
                    height={64}
                    alt="object.name"
                  />
                </Avatar>
              </ListItemAvatar>
              <ImageModal
                isOpen={isImageModalOpen}
                onClose={() => setIsImageModalOpen(false)}
                imageUrl={SERVERCONFIG.url + object.image}
              />
            </>
          }
        </ListItemButton>
      </Grid2>
      <Grid2 item size={{ xs: 10, sm: 11 }}>
        <ListItemButton
          style={{height: SPACINGS.xl}}
          key={object._id}
          divider
          onClick={handleClick}
        >
          <ListItemText
            sx={{ maxWidth: 'calc(100% - 16px)' }}
            primary={object.name}
            slotProps={{ primary: { style: STYLES.textTruncate }, secondary: { style: STYLES.textTruncate } }}
            secondary={object.description}
          />
          <ListItemText
            style={{ width: '12%', textAlign: 'right'}}
            primary={object.storage || ""}
            slotProps={{ primary: { style: STYLES.textTruncate } }}
          />
          <ListItemSecondaryAction>
            {
              props.secondary
            }
          </ListItemSecondaryAction>
        </ListItemButton>
      </Grid2>
    </Grid2>
  )
}

const styles = {
  imageListButton: {
    height: SPACINGS.xl,
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
  }
}