import { Checkbox, Grid2, ListItem, TextField } from "@mui/material"
import ClearIcon from '@mui/icons-material/Clear';
import React from 'react'
import Restricted from "../shared/Restricted"

export default function PackingListAdditionalEntry(props) {
  const entry = props.entry
  const isPacked = entry.isPacked

  const handleCheckboxChange = () => {
    props.onChange({name: entry.name, isPacked: !isPacked})
  }

  return (
    <Grid2 container>
      <Grid2 item size={{ xs: 10 }}>
        <ListItem
          key={entry._id || props.index}
          divider
          style={{display:'flex', justifyContent:'space-between'}}
          sx={{padding:0}}
        >
          <TextField
            style={{width: '100%'}}
            variant="standard"
            value={entry.name}
            onChange={(e) => props.onChange({name: e.target.value, isPacked: isPacked})}
          />
        </ListItem>
      </Grid2>
      <Grid2 item size={{ xs: 1 }} style={{justifyContent: 'center', display: 'flex'}}>
        <Checkbox
          checked={isPacked}
          onChange={handleCheckboxChange}
          style={{padding: 0}}
        />
      </Grid2>
      <Restricted to="packingLists.deleteEntry">
        <Grid2 item size={{ xs: 1 }} style={{justifyContent: 'center', display: 'flex', alignItems: 'center'}}>
          <ClearIcon onClick={props.onRemove} />
        </Grid2>
      </Restricted>
    </Grid2>
  )
}
