import React, { useEffect } from "react";
import { Grading } from "@mui/icons-material";
import { useGetOrCreatePackingList } from "../../api/packingListsApiClient";
import { useNavigate } from "react-router";
import { SPACINGS, STYLES } from "../../themes/MainTheme";
import { Button } from "@mui/material";

export default function GoToPackingList(props) {

  const navigate = useNavigate()

  const openPackingList = (response) => {
    navigate(`/packinglists/${response.packingListId}`)
  }

  const getOrCreateMutation = useGetOrCreatePackingList(openPackingList)

  const getOrCreatePackingList = () => {
    getOrCreateMutation.mutate({
      projectId: props.projectId,
      navigate
    })
  }

  // Error handling
  useEffect(() => {
    if (getOrCreateMutation.isError) {
      props.onError()
    }
  }, [getOrCreateMutation.isError, props])

  return (
    <Button style={{...STYLES.buttonV2, ...styles.topActionButton }} onClick={getOrCreatePackingList}>
      <Grading fontSize="medium" />
    </Button>
  );
}

const styles = {
  topActionButton: {
    width: SPACINGS.xl
  }
}