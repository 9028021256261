import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { useAddProject, useDeleteProject, useGetAssignedObjects, useGetProject, useUpdateProject } from "../../api/projectsApiClient";
import cropImage from "../../utils/imageCropper";
import { Box, Button, Container, Grid2, LinearProgress, List, Paper, Snackbar, Stack, TextField, Typography } from "@mui/material";
import { Delete, Save } from "@mui/icons-material";
import { SPACINGS, STYLES } from "../../themes/MainTheme";
import BackButton from "../shared/BackButton";
import Restricted from "../shared/Restricted";
import DeleteDialog from "../shared/DeleteDialog";
import ObjectsListRow from "../objects/ObjectsListRow";
import TransitionRight from "../shared/TransitionRight";
import SERVERCONFIG from "../../utils/serverConfig";
import ImageUploadButton from "../shared/ImageUploadButton";
import GoToPackingList from "../packinglists/GoToPackingList";

export default function ProjectDetail() {
  const params = useParams();
  const hasProject = params.id !== "add";

  const [formData, setFormData] = useState({
    name: '',
    description: '',
    image: null
  })
  const [qrcodeUrl, setqrcodeUrl] = useState("")
  const [imageUrl, setImageUrl] = useState("")
  const [snackbarVisible, setSnackbarVisible] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState("")
  const [showDeleteDialog, setShowDeleteDialog] = useState(false)

  const navigate = useNavigate()

  const onClose = () => {
    navigate('/projects')
  }

  const toggleSnackbar = (visible, message="") => {
    setSnackbarVisible(visible)
    setSnackbarMessage(message)
  
    if (visible) {
      setTimeout(() => setSnackbarVisible(false), 3000)
    }
  }

  const { data: project, isLoading: isLoadingProject, isError: isErrorProject } = useGetProject(params.id, navigate, hasProject)
  const { data: objects, isLoading: isLoadingObjects, isError: isErrorObjects } = useGetAssignedObjects(params.id, navigate, hasProject)
  const addMutation = useAddProject(onClose)
  const updateMutation = useUpdateProject(onClose)
  const deleteMutation = useDeleteProject(onClose)

  const handleCapture = (target) => {
    if (target.files) {
      if (target.files.length !== 0) {
        const file = target.files[0]

        cropImage(file, 500, 500).then((croppedBlob) => {
          setImageUrl(URL.createObjectURL(croppedBlob))
          setFormData({...formData, image: croppedBlob})
        });
      }
    }
  }

  const checkInput = () => {
    if (!formData.name) {
      toggleSnackbar(true, "Der Name darf nicht leer sein")
      return false
    }
    return true
  }

  const saveOrUpdateForm = async() => {
    if (checkInput()) {
      if (hasProject) {
        updateMutation.mutate({projectId: params.id, formData, navigate})
      } else {
        addMutation.mutate({formData, navigate})
      }
    }
  }

  useEffect(() => {
    if (project) {
      // Fill form
      setFormData({
        name: project.name,
        description: project.description,
        image: null
      })
      if (project.qrcode) {
        setqrcodeUrl(SERVERCONFIG.url + project.qrcode)
      }
      if (project.image) {
        setImageUrl(SERVERCONFIG.url + project.image)
      }
    }
  }, [project])

  // Error handling
  useEffect(() => {
    if (addMutation.isError || updateMutation.isError) {
      toggleSnackbar(true, "Fehler beim speichern des Projektes")
    } else if (deleteMutation.isError) {
      toggleSnackbar(true, "Fehler beim löschen des Projektes")
    } else if (isErrorProject) {
      toggleSnackbar(true, "Fehler beim laden des Projektes")
    } else if (isErrorObjects) {
      toggleSnackbar(true, "Fehler beim laden der Objekte")
    }
  }, [addMutation.isError, updateMutation.isError, deleteMutation.isError, isErrorProject, isErrorObjects])

  const isLoading = addMutation.isPending || updateMutation.isPending || deleteMutation.isPending || isLoadingProject || isLoadingObjects

  return (
    <Container maxWidth="md" disableGutters>
      <Paper square elevation={1} style={STYLES.detailHeader}>
        <Grid2 container style={{height: SPACINGS.ll}}>
          <Grid2 item size={{ xs: 8 }}>
            <Container style={{height: "100%"}}>
              <BackButton onBack={() => navigate(-1)} />
            </Container>
          </Grid2>
          <Grid2 item size={{ xs: 4 }} style={styles.rightAlignedGrid}>
            <Restricted to="packinglists.create">
              <GoToPackingList
                projectId={params.id}
                onError={() => toggleSnackbar(true, "Ladeliste konnte nicht geöffnet werden")}
              />
            </Restricted>
            <Restricted to={hasProject ? "projects.update" : "projects.create"}>
              <Button style={{...STYLES.buttonV2, ...styles.topActionButton}} onClick={saveOrUpdateForm}>
                <Save fontSize="medium" />
              </Button>
            </Restricted>
            { project &&
              <Restricted to="projects.delete">
                <Button style={{...STYLES.buttonV2, ...styles.topActionButton}} onClick={() => setShowDeleteDialog(true)}>
                  <Delete fontSize="medium" />
                </Button>
                <DeleteDialog
                  isOpen={showDeleteDialog}
                  onClose={() => setShowDeleteDialog(false)}
                  message={"Möchtest Du dieses Projekt wirklich löschen?"}
                  handleDelete={() => deleteMutation.mutate({projectId: params.id, navigate})}
                />
              </Restricted>
            }
          </Grid2>
        </Grid2>
      </Paper>
      { isLoading && <LinearProgress color="inherit" /> }
      <Container style={styles.mainContainer}>
        <Paper style={styles.imageContainer} square elevation={1}>
          <Box style={styles.imageBox}>
            { imageUrl &&
              <img src={imageUrl} alt={""} style={styles.image} />
            }
          </Box>
          <Restricted to={hasProject ? "projects.update" : "projects.create"}>
            <Box style={styles.uploadButtonsContainer}>
              <ImageUploadButton handleCapture={handleCapture} />
            </Box>
          </Restricted>
        </Paper>
        { qrcodeUrl &&
          <Paper style={styles.imageContainer} square elevation={1}>
            <Box style={styles.imageBox} >
              <a href={qrcodeUrl} style={{width: "100%"}} download={formData.name} target="_blank" rel="noreferrer">
                <img src={qrcodeUrl} alt={""} style={styles.image} />
              </a>
            </Box>
          </Paper>
        }
        <Paper style={styles.formContainer} square elevation={1}>
          <Stack spacing={2} style={styles.formStack}>
            <TextField
              label="Name"
              variant="standard"
              value={formData.name}
              onChange={(e) => setFormData({...formData, name: e.target.value })}
            />
            <TextField
              label="Beschreibung"
              variant="standard"
              value={formData.description}
              onChange={(e) => setFormData({...formData, description: e.target.value })}
            />
          </Stack>
        </Paper>
        <Paper style={styles.formContainer} square elevation={1}>
          { hasProject &&
            <List style={styles.listContainer}>
              { (objects != null && objects.length > 0) &&
                objects.map((object, i) => (
                  <ObjectsListRow
                    object={object}
                    key={i}
                    secondary={
                      <Typography>{object.assignedAmount}</Typography>
                    }
                  />
                ))
              }
              { (objects == null || objects.length === 0) &&
                <Typography>Diesem Projekt sind keine Objekte zugeordnet</Typography>
              }
            </List>
          }
        </Paper>
      </Container>
      <Snackbar
        open={snackbarVisible}
        message={snackbarMessage}
        slots={{transition: TransitionRight}}
      />
    </Container>
  )
}

const styles = {
  mainContainer: {
    width: "100%",
    height: "92%",
    padding: 0,
    maxHeight: "88%",
    overflowY: "auto"
  },
  imageContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "row"
  },
  formContainer: {
    flex: 1,
    display: "flex",
    marginTop: SPACINGS.s,
    marginBottom: SPACINGS.s
  },
  listContainer: {
    width: "100%",
    padding: SPACINGS.s
  },
  formStack: {
    width: "100%",
    padding: SPACINGS.s
  },
  uploadButtonsContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "column"
  },
  imageBox: {
    width: "50%",
    aspectRatio: 1,
    display: "flex"
  },
  image: {
    width: "100%",
    objectFit: "cover"
  },
  topActionButton: {
    width: SPACINGS.xl
  },
  rightAlignedGrid: {
    justifyContent: "right",
    flexDirection: "row",
    display: "flex"
  }
}