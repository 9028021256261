import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { useAddStorage, useDeleteStorage, useGetStorage, useGetStoredObjects, useUpdateStorage } from "../../api/storagesApiClient";
import { Box, Button, Container, Grid2, LinearProgress, List, Paper, Snackbar, Stack, TextField, Typography } from "@mui/material";
import { SPACINGS, STYLES } from "../../themes/MainTheme";
import BackButton from "../shared/BackButton";
import Restricted from "../shared/Restricted";
import { Delete, Save } from "@mui/icons-material";
import DeleteDialog from "../shared/DeleteDialog";
import SERVERCONFIG from "../../utils/serverConfig";
import TransitionRight from "../shared/TransitionRight";
import ObjectsListRow from "../objects/ObjectsListRow";

export default function StorageDetail() {
  const params = useParams();
  const hasStorage = params.id !== "add";

  const [formData, setFormData] = useState({
    name: ""
  })
  const [imageUrl, setImageUrl] = useState("")
  const [snackbarVisible, setSnackbarVisible] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState("")
  const [showDeleteDialog, setShowDeleteDialog] = useState(false)

  const navigate = useNavigate()

  const onClose = () => {
    navigate('/storages')
  }

  const toggleSnackbar = (visible, message="") => {
    setSnackbarVisible(visible)
    setSnackbarMessage(message)
  
    if (visible) {
      setTimeout(() => setSnackbarVisible(false), 3000)
    }
  }

  const { data: storage, isLoading: isLoadingStorage, isError: isErrorStorage } = useGetStorage(params.id, navigate, hasStorage)
  const { data: objects, isLoading: isLoadingObjects, isError: isErrorObjects } = useGetStoredObjects(params.id, navigate, hasStorage)
  const addMutation = useAddStorage(onClose)
  const updateMutation = useUpdateStorage(onClose)
  const deleteMutation = useDeleteStorage(onClose)

  const checkInput = () => {
    if (!formData.name) {
      toggleSnackbar(true, "Der Name darf nicht leer sein")
      return false
    }
    return true
  }

  const saveOrUpdateForm = async() => {
    if (checkInput()) {
      if (hasStorage) {
        updateMutation.mutate({ storageId: params.id, formData, navigate })
      } else {
        addMutation.mutate({ formData, navigate })
      }
    }
  }

  useEffect(() => {
    if (storage) {
      // Fill form
      setFormData({
        name: storage.name
      })
      if (storage.qrcode) {
        setImageUrl(SERVERCONFIG.url + storage.qrcode)
      }
    }
  }, [storage])

  // Error handling
  useEffect(() => {
    if (addMutation.isError || updateMutation.isError) {
      toggleSnackbar(true, "Fehler beim speichern des Lagerplatzes")
    } else if (deleteMutation.isError) {
      toggleSnackbar(true, "Fehler beim löschen des Lagerplatzes")
    } else if (isErrorStorage) {
      toggleSnackbar(true, "Fehler beim laden des Lagerplatzes")
    } else if (isErrorObjects) {
      toggleSnackbar(true, "Fehler beim laden der Objekte")
    }
  }, [addMutation.isError, updateMutation.isError, deleteMutation.isError, isErrorStorage, isErrorObjects])

  const isLoading = addMutation.isPending || updateMutation.isPending || deleteMutation.isPending || isLoadingStorage || isLoadingObjects

  return (
    <Container maxWidth="md" disableGutters>
      <Paper square elevation={1} style={STYLES.detailHeader}>
        <Grid2 container style={{height: SPACINGS.ll}}>
          <Grid2 item size={{ xs: 8 }}>
            <Container style={{height: "100%"}}>
              <BackButton onBack={() => navigate(-1)} />
            </Container>
          </Grid2>
          <Grid2 item size={{ xs: 4 }} style={styles.rightAlignedGrid}>
            <Restricted to={hasStorage ? "storages.update" : "storages.create"}>
              <Button style={{...STYLES.buttonV2, ...styles.topActionButton}} onClick={saveOrUpdateForm}>
                <Save fontSize="medium"/>
              </Button>
            </Restricted>
            { storage &&
              <Restricted to="storages.delete">
                <Button style={{...STYLES.buttonV2, ...styles.topActionButton}} onClick={() => setShowDeleteDialog(true)}>
                  <Delete fontSize="medium" />
                </Button>
                <DeleteDialog
                  isOpen={showDeleteDialog}
                  onClose={() => setShowDeleteDialog(false)}
                  message={"Möchtest du den Lagerplatz wirklich löschen?"}
                  handleDelete={() => deleteMutation.mutate({storageId: params.id, navigate})}
                />
              </Restricted>
            }
          </Grid2>
        </Grid2>
      </Paper>
      { isLoading && <LinearProgress color="inherit" /> }
      <Container style={styles.mainContainer}>
        <Paper style={styles.imageContainer} square elevation={1}>
          <Box style={styles.imageBox}>
            { imageUrl &&
              <a href={imageUrl} style={{width: "100%"}} download={formData.name} target="_blank" rel="noreferrer">
                <img src={imageUrl} alt={""} style={styles.image} />
              </a>
            }
          </Box>
        </Paper>
        <Paper style={styles.formContainer} square elevation={1}>
          <Stack spacing={2} style={styles.formStack}>
            <TextField
              label="Name"
              variant="standard"
              value={formData.name}
              onChange={(e) => setFormData({...formData, name: e.target.value})}
            />
          </Stack>
        </Paper>
        <Paper style={styles.formContainer} square elevation={1}>
          { hasStorage &&
            <List style={styles.listContainer}>
              { (objects != null && objects.length > 0) &&
                objects.map((object, i) => (
                  <ObjectsListRow
                    object={object}
                    key={i}
                    secondary={
                      <Typography>{object.storedAmount}</Typography>
                    }
                  />
                ))
              }
              { (objects == null || objects.length === 0) &&
                <Typography>In diesem Lagerplatz sind keine Objekte vorhanden</Typography>
              }
            </List>
          }
        </Paper>
      </Container>
      <Snackbar
        open={snackbarVisible}
        message={snackbarMessage}
        slots={{transition: TransitionRight}}
      />
    </Container>
  )
}

const styles = {
  mainContainer: {
    width: "100%",
    height: "92%",
    padding: 0,
    maxHeight: "88%",
    overflowY: "auto"
  },
  imageContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "row"
  },
  formContainer: {
    flex: 1,
    display: "flex",
    marginTop: SPACINGS.s,
    marginBottom: SPACINGS.s
  },
  listContainer: {
    width: "100%",
    padding: SPACINGS.s
  },
  formStack: {
    width: "100%",
    padding: SPACINGS.s
  },
  imageBox: {
    width: "50%",
    aspectRatio: 1,
    display: "flex"
  },
  image: {
    width: "100%",
    objectFit: "cover"
  },
  topActionButton: {
    width: SPACINGS.xl
  },
  rightAlignedGrid: {
    justifyContent: "right",
    flexDirection: "row",
    display: "flex"
  }
}