import { ListItemButton, ListItemText } from "@mui/material"
import { STYLES } from "../../themes/MainTheme"
import { useNavigate } from "react-router"

export default function PackingListsListRow(props) {
  const packingList = props.packingList
  const navigate = useNavigate()

  const handleClick = () => {
    if (props.onClick) {
      props.onClick(packingList._id)
    } else {
      navigate(`/packinglists/${packingList._id}`)
    }
  }

  return (
    <ListItemButton
      key={packingList._id}
      onClick={handleClick}
      divider
    >
      <ListItemText
        primary={packingList.name}
        slotProps={{ primary: { style: STYLES.textTruncate }, secondary: { style: STYLES.textTruncate } }}
        secondary={packingList.note}
      />
    </ListItemButton>
  )
}
