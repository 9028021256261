import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router";
import { Container, LinearProgress, List, Snackbar } from "@mui/material";
import ObjectsListRow from "./ObjectsListRow";
import { useInView } from 'react-intersection-observer';
import TransitionRight from "../shared/TransitionRight";
import Searchbar from "../shared/Searchbar";
import { STYLES } from "../../themes/MainTheme";
import { useGetAllObjects } from "../../api/objectsApiClient";

export default function ObjectsList() {

  const [searchQuery, setSearchQuery] = useState("")
  const [snackbarVisible, setSnackbarVisible] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState("")
  const hasRestoredState = useRef(false)

  const navigate = useNavigate()
  const { data, isLoading, isError, fetchNextPage, isFetchingNextPage } = useGetAllObjects(searchQuery, navigate)
  const { ref, inView } = useInView()
  const listKey = `listState-${window.location.pathname}`

  const toggleSnackbar = (visible, message="") => {
    setSnackbarVisible(visible)
    setSnackbarMessage(message)
  
    if (visible) {
      setTimeout(() => setSnackbarVisible(false), 3000)
    }
  }

  useEffect(() => {
    if (isError) {
      toggleSnackbar(true, "Fehler beim laden der Objekte")
    }
  }, [isError])

  useEffect(() => {
    if (inView) {
      fetchNextPage()
    }
  }, [fetchNextPage, inView])

  // Store the search query in the session storage
  const handleNavigate = (object) => {
    sessionStorage.setItem(`${listKey}-searchQuery`, searchQuery)
    navigate(`/${object._id}`)
  }

  // Restore the search query from the session storage
  useEffect(() => {
    if (hasRestoredState.current) return;
    const savedQuery = sessionStorage.getItem(`${listKey}-searchQuery`)
    if (savedQuery) setSearchQuery(savedQuery);
    hasRestoredState.current = true;
  }, [listKey, searchQuery]);

  return (
    <Container maxWidth="md" disableGutters>
      <Searchbar
        class="searchbar-list"
        searchText={searchQuery}
        onChange={(value) => setSearchQuery(value)}
        onClear={() => setSearchQuery("")}
      />
      { (isLoading || isFetchingNextPage) && <LinearProgress color="inherit" /> }
      <List style={STYLES.listContainer}>
        { data?.pages.map((page) => (
            page.data.map((object) => (
              <ObjectsListRow key={object._id} object={object} onClick={handleNavigate} />
            ))
          ))
        }
        { data?.pages.length > 0 &&
          <div ref={ref}></div>
        }
      </List>
      <Snackbar
        open={snackbarVisible}
        message={snackbarMessage}
        slots={{transition: TransitionRight}}
      />
    </Container>
  )
}