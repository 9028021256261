import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router"
import CssBaseline from '@mui/material/CssBaseline';
import './index.css';
import App from './App';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { Helmet } from 'react-helmet';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <div>
    <Helmet>
      <meta charSet="utf-8" />
      <title>M2Format Lager</title>
      <meta name="description" content="Webapp zur Lagerverwaltung von M2Format" />
    </Helmet>
    <BrowserRouter>
      <CssBaseline>
        <App />
      </CssBaseline>
    </BrowserRouter>
  </div>
);