import { ListItemButton, ListItemText } from "@mui/material"
import { STYLES } from "../../themes/MainTheme"
import { useNavigate } from "react-router"

export default function StoragesListRow(props) {
  const storage = props.storage
  const navigate = useNavigate()

  const handleClick = () => {
    if (props.onClick) {
      props.onClick(storage)
    } else {
      navigate(`/storages/${storage._id}`)
    }
  }

  return (
    <ListItemButton
      key={storage._id}
      divider
      onClick={handleClick}
    >
      <ListItemText
        primary={storage.name}
        slotProps={{ primary: { style: STYLES.textTruncate } }}
      />
    </ListItemButton>
  )
}