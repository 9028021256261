import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import SERVERCONFIG from "../utils/serverConfig"
import axios from 'axios'

const objectsEndpoint = SERVERCONFIG.url+'objects'
const api = axios.create({ baseURL: objectsEndpoint, withCredentials: SERVERCONFIG.withCredentials })

const handleError = (navigate, error) => {
  if (error.response && error.response.status === 401) {
    navigate("/login")
  } else {
    throw error
  }
}

const objectsApiClient = {
  getAllObjects: async(pageParam, query, navigate) => {
    try {
      const {data} = await api.post('/', {query, offset: pageParam}, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      return data
    } catch (error) {
      handleError(navigate, error)
    }
  },
  getObject: async(objectId, navigate) => {
    try {
      const {data} = await api.get('/'+objectId)
      return data
    } catch (error) {
      handleError(navigate, error)
    }
  },
  addObject: async(formData, navigate) => {
    try {
      const requestData = new FormData()
      requestData.append('name', formData.name)
      requestData.append('description', formData.description)
      requestData.append('amount', formData.amount)
      requestData.append('customer', formData.customer)
      if (formData.image) {
        requestData.append('image', formData.image, 'cropped.jpg')
      }

      const {data} = await api.post('/add/', requestData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })

      return data
    } catch (error) {
      handleError(navigate, error)
    }
  },
  updateObject: async(objectId, formData, navigate) => {
    try {
      const requestData = new FormData()
      requestData.append('name', formData.name)
      requestData.append('description', formData.description)
      requestData.append('amount', formData.amount)
      requestData.append('customer', formData.customer)
      if (formData.image) {
        requestData.append('image', formData.image, 'cropped.jpg')
      }

      const {data} = await api.put('/'+objectId, requestData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })

      return data
    } catch (error) {
      handleError(navigate, error)
    }
  },
  deleteObject: async(objectId, navigate) => {
    try {
      const {data} = await api.delete('/'+objectId)
      return data
    } catch (error) {
      handleError(navigate, error)
    }
  },
  storeObject: async(objectId, storageData, navigate) => {
    try {
      const {data} = await api.post('/store/'+objectId, storageData, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      return data
    } catch (error) {
      handleError(navigate, error)
    }
  },
  assignObject: async(objectId, projectData, navigate) => {
    try {
      const {data} = await api.post('/assign/'+objectId, projectData, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      return data
    } catch (error) {
      handleError(navigate, error)
    }
  },
  restoreObjectStorage: async(objectId, instanceData, navigate) => {
    try {
      const {data} = await api.post('/restoreStorage/'+objectId, instanceData, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      return data
    } catch (error) {
      handleError(navigate, error)
    }
  },
  updateCustomer: async(objectId, customerData, navigate) => {
    try {
      const {data} = await api.put('/customer/'+objectId, customerData, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      return data
    } catch (error) {
      handleError(navigate, error)
    }
  },
  addInstances: async(objectId, instanceData, navigate) => {
    try {
      const {data} = await api.post('/instances/'+objectId, instanceData, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      return data
    } catch (error) {
      handleError(navigate, error)
    }
  },
  removeInstances: async(objectId, instanceData, navigate) => {
    try {
      const {data} = await api.post('/delete-instances/'+objectId, instanceData, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      return data
    } catch (error) {
      handleError(navigate, error)
    }
  }
}

export default objectsApiClient

export const useGetAllObjects = (query, navigate) => {
  return useInfiniteQuery({
    queryFn: ({pageParam}) => objectsApiClient.getAllObjects(pageParam, query, navigate),
    queryKey: ['objects', {query}],
    initialPageParam: 0,
    getNextPageParam: (lastPage) => lastPage.next
  })
}

export const useGetObject = (objectId, navigate, enabled) => {
  return useQuery({
    queryFn: () => objectsApiClient.getObject(objectId, navigate),
    queryKey: ['object', {objectId}],
    retry: false,
    gcTime: 0,
    enabled: enabled
  })
}

export const useAddObject = (onSuccess) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({formData, navigate}) => objectsApiClient.addObject(formData, navigate),
    onSuccess: (res) => {
      queryClient.invalidateQueries({queryKey: ['object']})
      queryClient.invalidateQueries({queryKey: ['objects']})
      queryClient.invalidateQueries({queryKey: ['storedObjects']})
      queryClient.invalidateQueries({queryKey: ['assignedObjects']})
      queryClient.invalidateQueries({queryKey: ['customerAssignedObjects']})
      onSuccess(res)
    }
  })
}

export const useUpdateObject = (onSuccess) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({objectId, formData, navigate}) => objectsApiClient.updateObject(objectId, formData, navigate),
    onSuccess: (res) => {
      queryClient.invalidateQueries({queryKey: ['object']})
      queryClient.invalidateQueries({queryKey: ['objects']})
      queryClient.invalidateQueries({queryKey: ['storedObjects']})
      queryClient.invalidateQueries({queryKey: ['customerAssignedObjects']})
      queryClient.invalidateQueries({queryKey: ['projectAssignedObjects']})
      queryClient.invalidateQueries({queryKey: ['packingLists']})
      queryClient.invalidateQueries({queryKey: ['packingList']})
      onSuccess(res)
    }
  })
}

export const useDeleteObject = (onSuccess) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({objectId, navigate}) => objectsApiClient.deleteObject(objectId, navigate),
    onSuccess: (res) => {
      queryClient.invalidateQueries({queryKey: ['object']})
      queryClient.invalidateQueries({queryKey: ['objects']})
      queryClient.invalidateQueries({queryKey: ['storedObjects']})
      queryClient.invalidateQueries({queryKey: ['customerAssignedObjects']})
      queryClient.invalidateQueries({queryKey: ['projectAssignedObjects']})
      queryClient.invalidateQueries({queryKey: ['customerStorages']})
      queryClient.invalidateQueries({queryKey: ['packingLists']})
      queryClient.invalidateQueries({queryKey: ['packingList']})
      onSuccess(res)
    }
  })
}

export const useStoreObject = (onSuccess) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({objectId, storageData, navigate}) => objectsApiClient.storeObject(objectId, storageData, navigate),
    onSuccess: (res) => {
      queryClient.invalidateQueries({queryKey: ['object']})
      queryClient.invalidateQueries({queryKey: ['objects']})
      queryClient.invalidateQueries({queryKey: ['storedObjects']})
      queryClient.invalidateQueries({queryKey: ['customerAssignedObjects']})
      queryClient.invalidateQueries({queryKey: ['projectAssignedObjects']})
      queryClient.invalidateQueries({queryKey: ['customerStorages']})
      queryClient.invalidateQueries({queryKey: ['packingLists']})
      queryClient.invalidateQueries({queryKey: ['packingList']})
      onSuccess(res)
    }
  })
}

export const useAssignObject = (onSuccess) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({objectId, projectData, navigate}) => objectsApiClient.assignObject(objectId, projectData, navigate),
    onSuccess: (res) => {
      queryClient.invalidateQueries({queryKey: ['object']})
      queryClient.invalidateQueries({queryKey: ['objects']})
      queryClient.invalidateQueries({queryKey: ['storedObjects']})
      queryClient.invalidateQueries({queryKey: ['customerAssignedObjects']})
      queryClient.invalidateQueries({queryKey: ['projectAssignedObjects']})
      queryClient.invalidateQueries({queryKey: ['customerStorages']})
      queryClient.invalidateQueries({queryKey: ['packingLists']})
      queryClient.invalidateQueries({queryKey: ['packingList']})
      onSuccess(res)
    }
  })
}

export const useRestoreObjectStorage = (onSuccess) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({objectId, instanceData, navigate}) => objectsApiClient.restoreObjectStorage(objectId, instanceData, navigate),
    onSuccess: (res) => {
      queryClient.invalidateQueries({queryKey: ['object']})
      queryClient.invalidateQueries({queryKey: ['objects']})
      queryClient.invalidateQueries({queryKey: ['storedObjects']})
      queryClient.invalidateQueries({queryKey: ['customerAssignedObjects']})
      queryClient.invalidateQueries({queryKey: ['projectAssignedObjects']})
      queryClient.invalidateQueries({queryKey: ['customerStorages']})
      queryClient.invalidateQueries({queryKey: ['packingLists']})
      queryClient.invalidateQueries({queryKey: ['packingList']})
      onSuccess(res)
    }
  })
}

export const useUpdateCustomer = (onSuccess) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({objectId, customerData, navigate}) => objectsApiClient.updateCustomer(objectId, customerData, navigate),
    onSuccess: (res) => {
      queryClient.invalidateQueries({queryKey: ['object']})
      queryClient.invalidateQueries({queryKey: ['objects']})
      queryClient.invalidateQueries({queryKey: ['customerAssignedObjects']})
      queryClient.invalidateQueries({queryKey: ['customerStorages']})
      onSuccess(res)
    }
  })
}

export const useAddInstances = (onSuccess) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({objectId, instanceData, navigate}) => objectsApiClient.addInstances(objectId, instanceData, navigate),
    onSuccess: (res) => {
      queryClient.invalidateQueries({queryKey: ['object']})
      queryClient.invalidateQueries({queryKey: ['objects']})
      queryClient.invalidateQueries({queryKey: ['storedObjects']})
      queryClient.invalidateQueries({queryKey: ['customerAssignedObjects']})
      queryClient.invalidateQueries({queryKey: ['projectAssignedObjects']})
      queryClient.invalidateQueries({queryKey: ['customerStorages']})
      queryClient.invalidateQueries({queryKey: ['packingLists']})
      queryClient.invalidateQueries({queryKey: ['packingList']})
      onSuccess(res)
    }
  })
}

export const useRemoveInstances = (onSuccess) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({objectId, instanceData, navigate}) => objectsApiClient.removeInstances(objectId, instanceData, navigate),
    onSuccess: (res) => {
      queryClient.invalidateQueries({queryKey: ['object']})
      queryClient.invalidateQueries({queryKey: ['objects']})
      queryClient.invalidateQueries({queryKey: ['storedObjects']})
      queryClient.invalidateQueries({queryKey: ['customerAssignedObjects']})
      queryClient.invalidateQueries({queryKey: ['projectAssignedObjects']})
      queryClient.invalidateQueries({queryKey: ['customerStorages']})
      queryClient.invalidateQueries({queryKey: ['packingLists']})
      queryClient.invalidateQueries({queryKey: ['packingList']})
      onSuccess(res)
    }
  })
}