import { Avatar, Checkbox, Grid2, ListItemAvatar, ListItemButton, ListItemText } from "@mui/material"
import DeleteIcon from '@mui/icons-material/Delete';
import React, {useEffect, useState} from 'react'
import SERVERCONFIG from "../../utils/serverConfig"
import { COLORS, STYLES } from "../../themes/MainTheme"
import { useNavigate } from "react-router"
import { useAssignObject, useRestoreObjectStorage } from "../../api/objectsApiClient"
import { useRemoveObject } from "../../api/packingListsApiClient"
import Restricted from "../shared/Restricted"
import CONSTS from "../../utils/consts";

export default function PackingListEntry(props) {
  const project = props.project
  const entry = props.entry
  const object = entry.object
  const storageName = object.instances[0].storage != null ? `${object.instances[0].storage.name} ${object.instances[0].storageSlots.join(",")}` : ""
  const lastStorageName = object.instances[0].lastStorage != null ? `${object.instances[0].lastStorage.name} ${object.instances[0].lastStorageSlots.join(",")}` : ""
  const amount = object.instances.length

  const [isPacked, setIsPacked] = useState(object.instances.every(instance => instance.project.name !== CONSTS.emptyProjectName));

  const navigate = useNavigate()

  const handleClick = () => {
    if (props.onClick) {
      props.onClick(object)
    } else {
      // Open object
      navigate(`/${object._id}`)
    }
  }

  const assignMutation = useAssignObject((res) => {
    setIsPacked(res.instances.every(instance => instance.project.name !== CONSTS.emptyProjectName))
    props.showSnackbar(true, "Objekte zugewiesen")
  })
  const restoreStorageMutation = useRestoreObjectStorage((res) => {
    setIsPacked(res.instances.every(instance => instance.project.name !== CONSTS.emptyProjectName))
    props.showSnackbar(true, "Objekte zurückgestellt")
  })
  const removeFromListMutation = useRemoveObject(() => props.showSnackbar(true, "Objekte entfernt"))

  const handleCheckboxChange = () => {
    if (!isPacked) {
      // Assign object if not packed
      assignMutation.mutate({
        objectId: object._id,
        projectData: {
          project: project,
          instancesToAssign: object.instances
        },
        navigate
      })
    } else {
      // Restore last storage
      restoreStorageMutation.mutate({
        objectId: object._id,
        instanceData: {
          instancesToRestore: object.instances
        },
        navigate
      })
    }
  }

  //removeObject(packingListId, objectData, navigate),
  const removeFromPackingList = () => {
    removeFromListMutation.mutate({
      packingListId: object.instances[0].packingList,
      objectData: {
        object: object._id,
        instances: object.instances
      },
      navigate
    })
  }

  useEffect(() => {
    if (assignMutation.isError) {
      props.showSnackbar(true, "Fehler beim Zuweisen der Objekte")
    }
    if (restoreStorageMutation.isError) {
      props.showSnackbar(true, "Fehler beim Zurückstellen der Objekte")
    }
  }, [assignMutation.isError, restoreStorageMutation.isError, props])

  return (
    <Grid2 container>
      <Grid2 item size={{ xs: 10 }}>
        <ListItemButton
          key={object._id}
          divider
          onClick={handleClick}
          style={{display:'flex', justifyContent:'space-between'}}
          sx={{padding:0}}
        >
          <ListItemAvatar>
            { object.thumbnail != null &&
              (
                <Avatar variant="square" sx={{width: 48, height: 48}}>
                  <img src={SERVERCONFIG.url + object.thumbnail} width={48} height={48} alt="object.name" />
                </Avatar>
              )
            }
            {
              object.thumbnail == null &&
              (
                <div style={{backgroundColor: COLORS.primaryBackground}}></div>
              )
            }
          </ListItemAvatar>
          <ListItemText
            style={{width: '88%'}}
            primary={object.name}
            slotProps={{ primary: { style: STYLES.textTruncate }, secondary: { style: STYLES.textTruncate } }}
            secondary={storageName.trim() !== CONSTS.emptyStorageName ? storageName : lastStorageName}
          />
          <ListItemText
            style={{ width: '12%', textAlign: 'center'}}
            primary={amount}
            slotProps={{ primary: { style: STYLES.textTruncate } }}
          />
        </ListItemButton>
      </Grid2>
      <Grid2 item size={{ xs: 1 }} style={{justifyContent: 'center', display: 'flex'}}>
        <Checkbox
          checked={isPacked}
          onChange={handleCheckboxChange}
          style={{padding: 0}}
        />
      </Grid2>
      <Restricted to="packingLists.deleteEntry">
        <Grid2 item size={{ xs: 1 }} style={{justifyContent: 'center', display: 'flex', alignItems: 'center'}}>
          <DeleteIcon onClick={removeFromPackingList} />
        </Grid2>
      </Restricted>
    </Grid2>
  )
}
