import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import SERVERCONFIG from "../utils/serverConfig"
import axios from 'axios'

const packingListsEndpoint = SERVERCONFIG.url+'packingLists'
const api = axios.create({ baseURL: packingListsEndpoint, withCredentials: SERVERCONFIG.withCredentials })

const handleError = (navigate, error) => {
  if (error.response && error.response.status === 401) {
    navigate('/login')
  } else {
    throw error
  }
}

const packingListsApiClient = {
  getAllPackingLists: async(pageParam, query, navigate) => {
    try {
      const {data} = await api.post('/', {query, offset: pageParam}, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      return data
    } catch (error) {
      handleError(navigate, error)
    }
  },
  getPackingList: async(packingListId, navigate) => {
    try {
      const {data} = await api.get('/'+packingListId)
      return data
    } catch (error) {
      handleError(navigate, error)
    }
  },
  addPackingList: async(formData, navigate) => {
    try {
      const {data} = await api.post('/add/', formData, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      return data
    } catch (error) {
      handleError(navigate, error)
    }
  },
  updatePackingList: async(packingListId, formData, navigate) => {
    try {
      const {data} = await api.put('/'+packingListId, formData, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      return data
    } catch (error) {
      handleError(navigate, error)
    }
  },
  deletePackingList: async(packingListId, navigate) => {
    try {
      const {data} = await api.delete('/'+packingListId)
      return data
    } catch (error) {
      handleError(navigate, error)
    }
  },
  // Gets or creates packing list for a project
  getOrCreatePackingList: async(projectId, navigate) => {
    try {
      const {data} = await api.post('/project', {projectId}, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      return data
    } catch (error) {
      handleError(navigate, error)
    }
  },
  packObject: async(packingListId, objectData, navigate) => {
    try {
      const {data} = await api.post('/pack/'+packingListId, objectData, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      return data
    } catch (error) {
      handleError(navigate, error)
    }
  },
  removeObject: async(packingListId, objectData, navigate) => {
    try {
      console.log(packingListId)
      console.log(objectData)
      const {data} = await api.post('/unpack/'+packingListId, objectData, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      return data
    } catch (error) {
      handleError(navigate, error)
    }
  }
}

export default packingListsApiClient

export const useGetAllPackingLists = (query, navigate, enabled) => {
  return useInfiniteQuery({
    queryFn: ({pageParam}) => packingListsApiClient.getAllPackingLists(pageParam, query, navigate),
    queryKey: ['packingLists', {query}],
    initialPageParam: 0,
    getNextPageParam: (lastPage) => lastPage.next,
    enabled: enabled
  })
}

export const useGetPackingList = (packingListId, navigate) => {
  return useQuery({
    queryFn: () => packingListsApiClient.getPackingList(packingListId, navigate),
    queryKey: ['packingList', {packingListId}],
    retry: false,
    gcTime: 0
  })
}

export const useAddPackingList = (onSuccess) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({formData, navigate}) => packingListsApiClient.addPackingList(formData, navigate),
    onSuccess: (res) => {
      queryClient.invalidateQueries({queryKey: ['packingLists']})
      onSuccess(res)
    }
  })
}

export const useUpdatePackingList = (onSuccess) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({packingListId, formData, navigate}) => packingListsApiClient.updatePackingList(packingListId, formData, navigate),
    onSuccess: (res) => {
      queryClient.invalidateQueries({queryKey: ['packingLists']})
      onSuccess(res)
    }
  })
}

export const useDeletePackingList = (onSuccess) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({packingListId, navigate}) => packingListsApiClient.deletePackingList(packingListId, navigate),
    onSuccess: (res) => {
      queryClient.invalidateQueries({queryKey: ['packingLists']})
      onSuccess(res)
    }
  })
}

export const useGetOrCreatePackingList = (onSuccess) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({projectId, navigate}) => packingListsApiClient.getOrCreatePackingList(projectId, navigate),
    onSuccess: (res) => {
      queryClient.invalidateQueries({queryKey: ['packingList']})
      onSuccess(res)
    }
  })
}

export const usePackObject = (onSuccess) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({packingListId, objectData, navigate}) => packingListsApiClient.packObject(packingListId, objectData, navigate),
    onSuccess: (res) => {
      queryClient.invalidateQueries({queryKey: ['packingLists']})
      queryClient.invalidateQueries({queryKey: ['packingList']})
      queryClient.invalidateQueries({queryKey: ['objects']})
      onSuccess(res)
    }
  })
}

export const useRemoveObject = (onSuccess) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({packingListId, objectData, navigate}) => packingListsApiClient.removeObject(packingListId, objectData, navigate),
    onSuccess: (res) => {
      queryClient.invalidateQueries({queryKey: ['packingLists']})
      queryClient.invalidateQueries({queryKey: ['packingList']})
      onSuccess(res)
    }
  })
}