import { Button, Container, Grid2, LinearProgress, List, Paper, Snackbar } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { SPACINGS, STYLES } from '../../themes/MainTheme';
import useGlobal from '../shared/GlobalContext';
import { useGetAllStorages } from '../../api/storagesApiClient';
import CONSTS from '../../utils/consts';
import { useNavigate } from 'react-router';
import { useGetAllProjects } from '../../api/projectsApiClient';
import { useAssignObject, useStoreObject } from '../../api/objectsApiClient';
import { Close } from "@mui/icons-material";
import Searchbar from '../shared/Searchbar';
import StoragesListRow from '../storages/StoragesListRow';
import ProjectsListRow from '../projects/ProjectsListRow';
import StorageSlotsDialog from '../shared/StorageSlotsDialog';
import TransitionRight from '../shared/TransitionRight';
import { useGetAllPackingLists, usePackObject } from '../../api/packingListsApiClient';
import PackingListsListRow from '../packinglists/PackingListsListRow';
import { useInView } from 'react-intersection-observer';

export default function ListAssign() {

  const [searchQuery, setSearchQuery] = useState("")
  const [selectedStorageId, setSelectedStorageId] = useState("")
  const [showStorageSlotsDialog, setShowStorageSlotsDialog] = useState(false)
  const [snackbarVisible, setSnackbarVisible] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState("")
  const navigate = useNavigate()

  const {
    toggleListAssignModal,
    setInstancesToStore,
    instancesToStore,
    assignTypeId
  } = useGlobal()
  const { ref, inView } = useInView()

  const toggleSnackbar = (visible, message="") => {
    setSnackbarVisible(visible)
    setSnackbarMessage(message)
  
    if (visible) {
      setTimeout(() => {
        setSnackbarVisible(false)
      }, 1000)
    }
  }

  const getAllStoragesQuery = useGetAllStorages(
    searchQuery,
    navigate,
    assignTypeId === CONSTS.storageTypeId
  )

  const getAllProjectsQuery = useGetAllProjects(
    searchQuery,
    navigate,
    assignTypeId === CONSTS.projectTypeId
  )

  const getAllPackingListsQuery = useGetAllPackingLists(
    searchQuery,
    navigate,
    assignTypeId === CONSTS.packingListTypeId
  )

  const storeMutation = useStoreObject((res) => {
    navigate(`/${res._id}`)
    toggleListAssignModal(false)
    setInstancesToStore(null)
    window.location.reload() // TODO: this could be improved (proper cache invalidation)
  })

  const assignMutation = useAssignObject((res) => {
    navigate(`/${res._id}`)
    toggleListAssignModal(false)
    setInstancesToStore(null)
    window.location.reload() // TODO: this could be improved (proper cache invalidation)
  })

  const packMutation = usePackObject((res) => {
    toggleListAssignModal(false)
    setInstancesToStore(null)
  })

  const storeObject = (assignToId, storageSlots) => {
    if (assignTypeId === CONSTS.storageTypeId) {
      storeMutation.mutate({
        objectId: instancesToStore.objectId,
        storageData: {
          storage: assignToId,
          storageSlots: storageSlots,
          instancesToStore: instancesToStore.instances
        },
        navigate
      })
    } else if (assignTypeId === CONSTS.projectTypeId) {
      assignMutation.mutate({
        objectId: instancesToStore.objectId,
        projectData: {
          project: assignToId,
          instancesToAssign: instancesToStore.instances
        },
        navigate
      })
    } else if (assignTypeId === CONSTS.packingListTypeId) {
      packMutation.mutate({
        packingListId: assignToId,
        objectData: {
          object: instancesToStore.objectId,
          instances: instancesToStore.instances
        },
        navigate
      })
    }
  }

  const handleStorageClick = (storageId) => {
    setSelectedStorageId(storageId)
    setShowStorageSlotsDialog(true)
  }

  const handleProjectClick = (projectId) => {
    toggleListAssignModal(false)
    storeObject(projectId)
  }

  const handlePackingListClick = (packingListId) => {
    toggleListAssignModal(false)
    storeObject(packingListId)
  }

  const confirmStorageSlots = (storageSlots) => {
    toggleListAssignModal(false)
    storeObject(selectedStorageId, storageSlots)
  }

  const fetchMore = useCallback(() => {
    if (getAllStoragesQuery.data) {
      getAllStoragesQuery.fetchNextPage()
    } else if (getAllProjectsQuery.data) {
      getAllProjectsQuery.fetchNextPage()
    } else if (getAllPackingListsQuery.data) {
      getAllPackingListsQuery.fetchNextPage()
    }
  }, [getAllStoragesQuery, getAllProjectsQuery, getAllPackingListsQuery])

  const onClose = () => {
    toggleListAssignModal(false)
    setInstancesToStore(null)
  }

  useEffect(() => {
    if (inView) {
      fetchMore()
    }
  }, [inView, fetchMore])

  // Error handling
  useEffect(() => {
    if (getAllStoragesQuery.isError) {
      toggleSnackbar(true, "Fehler beim laden der Lagerplätze")
    } else if (getAllProjectsQuery.isError) {
      toggleSnackbar(true, "Fehler beim laden der Projekte")
    } else if (getAllPackingListsQuery.isError) {
      toggleSnackbar(true, "Fehler beim laden der Ladelisten")
    } else if (storeMutation.isError) {
      toggleSnackbar(true, "Fehler beim lagern des Objektes")
    } else if (assignMutation.isError) {
      toggleSnackbar(true, "Fehler beim zuordnen des Objektes")
    } else if (packMutation.isError) {
      toggleSnackbar(true, "Fehler beim packen des Objektes")
    }
  }, [getAllStoragesQuery.isError, getAllProjectsQuery.isError, getAllPackingListsQuery.isError, storeMutation.isError, assignMutation.isError, packMutation.isError])

  const isLoading =  getAllStoragesQuery.isLoading || getAllProjectsQuery.isLoading || getAllPackingListsQuery.isLoading || storeMutation.isPending || assignMutation.isPending || packMutation.isPending

  return (
    <>
      <Paper square elevation={3}>
        <Grid2 container style={{height: SPACINGS.xl}}>
          <Grid2 item size={{ xs: 10 }} style={{paddingLeft: SPACINGS.s}}>
          </Grid2>
          <Grid2 item size={{ xs: 2 }} style={{textAlign: "center"}}>
            <Button sx={{...STYLES.button, ...styles.closeButton }} onClick={onClose}>
              <Close fontSize="large" />
            </Button>
          </Grid2>
        </Grid2>
      </Paper>
      <Container style={styles.container}>
        <LinearProgress style={{visibility: isLoading ? "visible" : "hidden", marginBottom: SPACINGS.m}} />
        <Container>
          <Searchbar
            searchText={searchQuery}
            onChange={(value) => setSearchQuery(value)}
            onClear={() => setSearchQuery("")}
          />
          { assignTypeId === CONSTS.storageTypeId &&
            <List style={STYLES.listContainer}>
              { getAllStoragesQuery.data?.pages.map((page) => (
                  page.data.map((storage) => (
                    <StoragesListRow
                      storage={storage}
                      key={storage._id}
                      onClick={handleStorageClick}
                    />
                  ))
                ))
              }
              { getAllStoragesQuery.data?.pages.length > 0 &&
                <div ref={ref}></div>
              }
            </List>
          }
          { assignTypeId === CONSTS.projectTypeId &&
            <List style={STYLES.listContainer}>
              { getAllProjectsQuery.data?.pages.map((page) => (
                  page.data.map((project) => (
                    <ProjectsListRow
                      key={project._id}
                      project={project}
                      onClick={handleProjectClick}
                    />
                  ))
                ))
              }
              { getAllProjectsQuery.data?.pages.length > 0 &&
                <div ref={ref}></div>
              }
            </List>
          }
          { assignTypeId === CONSTS.packingListTypeId &&
            <List style={STYLES.listContainer}>
              { getAllPackingListsQuery.data?.pages.map((page) => (
                  page.data.map((packingList) => (
                    <PackingListsListRow
                      key={packingList._id}
                      packingList={packingList}
                      onClick={handlePackingListClick}
                    />
                  ))
                ))
              }
              { getAllPackingListsQuery.data?.pages.length > 0 &&
                <div ref={ref}></div>
              }
            </List>
          }
          <StorageSlotsDialog
            isOpen={showStorageSlotsDialog}
            onClose={() => setShowStorageSlotsDialog(false)}
            onConfirm={confirmStorageSlots}
            message={"In welche Fächer soll das Objekt gelagert werden?"}
          />
          <Snackbar
            open={snackbarVisible}
            message={snackbarMessage}
            slots={{transition: TransitionRight}}
          />
        </Container>
      </Container>
    </>
  )
}

const styles = {
  container: {
    width: "100%",
    height: "92%",
    padding: 0,
    maxHeight: "88%",
    overflowY: "auto"
  },
  closeButton: {
    width: SPACINGS.xl
  }
}