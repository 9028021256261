import React, { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router"
import { useAddCustomer, useDeleteCustomer, useGetAssignedObjects, useGetCustomer, useGetStorages, useUpdateCustomer } from "../../api/customersApiClient";
import { Button, Container, Divider, Grid2, LinearProgress, List, ListItemText, Paper, Snackbar, Stack, TextField, Typography } from "@mui/material";
import { SPACINGS, STYLES } from "../../themes/MainTheme";
import BackButton from "../shared/BackButton";
import Restricted from "../shared/Restricted";
import { Delete, Save } from "@mui/icons-material";
import TransitionRight from "../shared/TransitionRight";
import DeleteDialog from "../shared/DeleteDialog";
import ObjectsListRow from "../objects/ObjectsListRow";

export default function CustomerDetail() {
  const params = useParams()
  const hasCustomer = params.id !== "add";

  const [formData, setFormData] = useState({
    name: '',
    note: ''
  })
  const [snackbarVisible, setSnackbarVisible] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState("")
  const [showDeleteDialog, setShowDeleteDialog] = useState(false)

  const navigate = useNavigate()

  const onClose = () => {
    navigate('/customers')
  }

  const toggleSnackbar = (visible, message="") => {
    setSnackbarVisible(visible)
    setSnackbarMessage(message)
  
    if (visible) {
      setTimeout(() => setSnackbarVisible(false), 3000)
    }
  }

  const { data: customer, isLoading: isLoadingCustomer, isError: isErrorCustomer } = useGetCustomer(params.id, navigate, hasCustomer)
  const { data: objects, isLoading: isLoadingObjects, isError: isErrorObjects } =  useGetAssignedObjects(params.id, navigate, hasCustomer)
  const { data: storages, isLoading: isLoadingStorages, isError: isErrorStorages } = useGetStorages(params.id, navigate, hasCustomer)
  const addMutation = useAddCustomer(onClose)
  const updateMutation = useUpdateCustomer(onClose)
  const deleteMutation = useDeleteCustomer(onClose)

  const checkInput = () => {
    if (!formData.name) {
      toggleSnackbar(true, "Der Name darf nicht leer sein")
      return false
    }
    return true
  }

  const saveOrUpdateForm = async() => {
    if (checkInput()) {
      if (hasCustomer) {
        updateMutation.mutate({customerId: params.id, formData, navigate})
      } else {
        addMutation.mutate({formData, navigate})
      }
    }
  }  

  useEffect(() => {
    if (customer) {
      // Fill form
      setFormData({
        name: customer.name,
        note: customer.note
      })
    }
  }, [customer])

  // Error handling
  useEffect(() => {
    if (addMutation.isError || updateMutation.isError) {
      toggleSnackbar(true, "Fehler beim speichern des Kunden")
    } else if (deleteMutation.isError) {
      toggleSnackbar(true, "Fehler beim löschen des Kunden")
    } else if (isErrorCustomer) {
      toggleSnackbar(true, "Fehler beim laden des Kunden")
    } else if (isErrorObjects) {
      toggleSnackbar(true, "Fehler beim laden der Objekte")
    } else if (isErrorStorages) {
      toggleSnackbar(true, "Fehler beim laden der Lagerplätze")
    }
  }, [addMutation.isError, updateMutation.isError, deleteMutation.isError, isErrorCustomer, isErrorObjects, isErrorStorages])

  const isLoading = addMutation.isPending || updateMutation.isPending || deleteMutation.isPending || isLoadingCustomer || isLoadingObjects || isLoadingStorages

  return (
    <Container maxWidth="md" disableGutters>
      <Paper square elevation={1} style={STYLES.detailHeader}>
        <Grid2 container style={{height: SPACINGS.ll}}>
          <Grid2 item size={{ xs: 8 }}>
            <Container style={{height: "100%"}}>
              <BackButton onBack={() => navigate(-1)} />
            </Container>
          </Grid2>
          <Grid2 item size={{ xs: 4 }} style={styles.rightAlignedGrid}>
            <Restricted to={hasCustomer ? "customers.update" : "customers.create"}>
              <Button style={{...STYLES.buttonV2, ...styles.topActionButton}} onClick={saveOrUpdateForm}>
                <Save fontSize="medium"/>
              </Button>
            </Restricted>
            { customer &&
              <Restricted to="customers.delete">
                <Button style={{...STYLES.buttonV2, ...styles.topActionButton}} onClick={() => setShowDeleteDialog(true)}>
                  <Delete fontSize="medium"/>
                </Button>
                <DeleteDialog
                  isOpen={showDeleteDialog}
                  onClose={() => setShowDeleteDialog(false)}
                  message={"Möchtest Du diesen Kunden wirklich löschen?"}
                  handleDelete={() => deleteMutation.mutate({customerId: params.id, navigate})}
                />
              </Restricted>
            }
          </Grid2>
        </Grid2>
      </Paper>
      { isLoading && <LinearProgress color="inherit" /> }
      <Container style={styles.mainContainer} >
        <Paper style={styles.formContainer} square elevation={1}>
          <Stack spacing={2} style={styles.formStack}>
            <TextField
              label="Name"
              variant="standard"
              value={formData.name}
              onChange={(e) => setFormData({...formData, name: e.target.value })}
            />
            <TextField
              label="Notiz"
              variant="standard"
              value={formData.note}
              onChange={(e) => setFormData({...formData, note: e.target.value })}
            />
          </Stack>
        </Paper>
        { (hasCustomer && objects != null && objects.length > 0 )  &&
            <>
              Objekte
              <Paper style={styles.formContainer} square elevation={1}>
                <List style={styles.listContainer}>
                  {
                    objects && objects.map((object, i) => (
                      <ObjectsListRow
                        object={object}
                        key={i}
                        secondary={
                          <Typography>{object.assignedAmount}</Typography>
                        }
                      />
                    ))
                  }
                </List>
              </Paper>
            </>
          }
          { (hasCustomer && storages != null && storages.length > 0 )  &&
            <>
              Lagerplätze: {storages.length}
              <Paper style={styles.formContainer} square elevation={1}>
                  <List style={styles.listContainer}>
                    {
                      storages && storages.map((storage, i) => (
                        <>
                          <ListItemText
                            primary={storage}
                            slotProps={{ primary: { style: STYLES.textTruncate } }}
                          />
                          <Divider />
                        </>
                      ))
                    }
                  </List>
              </Paper>
            </>
          }
      </Container>
      <Snackbar
        open={snackbarVisible}
        message={snackbarMessage}
        slots={{transition: TransitionRight}}
      />
    </Container> 
  )
}

const styles = {
  mainContainer: {
    width: "100%",
    height: "92%",
    padding: 0,
    maxHeight: "88%",
    overflowY: "auto"
  },
  imageContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "row"
  },
  formContainer: {
    flex: 1,
    display: "flex",
    marginTop: SPACINGS.s,
    marginBottom: SPACINGS.s
  },
  listContainer: {
    width: "100%",
    padding: SPACINGS.s
  },
  formStack: {
    width: "100%",
    padding: SPACINGS.s
  },
  uploadButtonsContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "column"
  },
  imageBox: {
    width: "50%",
    aspectRatio: 1,
    display: "flex"
  },
  image: {
    width: "100%",
    objectFit: "cover"
  },
  topActionButton: {
    width: SPACINGS.xl
  },
  rightAlignedGrid: {
    justifyContent: "right",
    flexDirection: "row",
    display: "flex"
  }
}