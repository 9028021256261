import React, { useEffect, useState } from "react"
import './App.css';

import MuiTheme from "./themes/MuiTheme";
import Header from "./components/Header";
import Routing from "./Routing";
import { GlobalProvider } from "./components/shared/GlobalContext";
import { QueryCache, QueryClient, QueryClientProvider } from "@tanstack/react-query";
import PermissionProvider from "./components/shared/PermissionProvider";
import axios from "axios";
import SERVERCONFIG from "./utils/serverConfig";
import { useNavigate } from "react-router";
import ROLES from "./utils/roles";

function App() {

  const [userRole, setUserRole] = useState('none')
  const navigate = useNavigate()

  useEffect(() => {
    axios.get(SERVERCONFIG.url+'auth/role', { withCredentials: true })
      .then(response => {
        setUserRole(response.data.role)
      })
      .catch(error => {
        navigate('/login')
      })
  }, [navigate])

  const queryClient = new QueryClient({
    queryCache: new QueryCache({}),
    defaultOptions: {
      queries: {
        retry: 2
      }
    }
  })

  return (
    <QueryClientProvider client={queryClient}>
      <PermissionProvider permissions={ROLES[userRole]}>
        <MuiTheme>
          <Header role={userRole} />
          <GlobalProvider>
            <Routing role={userRole} />
          </GlobalProvider>
        </MuiTheme>
      </PermissionProvider>
    </QueryClientProvider>
  );
}

export default App;