import React from "react";
import BottomActionbar from "../shared/BottomActionbar";
import ObjectsList from "../objects/ObjectsList";
import Restricted from "../shared/Restricted";
import { useNavigate } from "react-router";
import useGlobal from "../shared/GlobalContext";

export default function Objects() {

  const { toggleQrModal } = useGlobal()
  const navigate = useNavigate();

  return (
    <div className="container">
      <Restricted to="objects.list">
        <ObjectsList />
        <BottomActionbar
          showAdd={true}
          onClickPlus={() => navigate("/add")}
          onClickQr={() => toggleQrModal(true)}
          addPermission="objects.create"
        />
      </Restricted>
    </div>
  );
}